import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "メドレー エンジニア・デザイナーブログをリニューアルしました",
  "date": "2022-04-01T07:04:16.000Z",
  "slug": "entry/2022/04/01/160416",
  "tags": null,
  "hero": "./2022_04_01.png",
  "heroAlt": "ブログリニューアル"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`皆さん、こんにちは。エンジニア・技術広報の平木です。`}</p>
    <p>{`以前からご覧になっていただいていた方にはお分かりかと思いますが、3/18 に今ご覧いただいているエンジニア・デザイナーブログを「Developer Portal」という名称に変更して 2017 年以来 5 年ぶりにリニューアルをしました。今回はリニューアルについて、目指すところと若干の技術的な側面をお伝えできればと思います。`}</p>
    <h2>{`ブログトップ新旧比較`}</h2>
    <h3>{`旧`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/478367b06c002dc00f04a07e4063d628/d9ed5/2022_04_01_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAABYlAAAWJQFJUiTwAAABJklEQVQoz6WNbWvCMBSFw7Sp2jRFmzStL+BksE/7ur/TgmWg7JfrcG3axLZJVVQ6qgiD7XA53JfnckDXIn+ojkX6mIGHNxMRwzpX1yIQ0V+ejQthINqxCLTIOwle3OCNTl7dgGKv02J+sL7j3ydD2zMxsx2f+M9svKD+vIcZtL3HySZmJmY95+zQ9jCducECuVPkTq3RBI2mDp0N2XwwHF/JBvbQcAwAMG8LAmBcHDYjbI0t7GkAwngdxuuo8Wi5iq5NswzjVRSvwsu+DS8/PsHxHwKHlsokKTcbLYTKuJayLgrF02q7Ubtd9bU91PXhVjfJdVUpKXVZHrXWSmmlqqKo8lwXRSVErfV9cnuQ+72QMs8Fz7Ik5eeGZ99JmuV5yrkQ8u75BCXzFJTxtbgSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "旧ブログ",
            "title": "旧ブログ",
            "src": "/static/478367b06c002dc00f04a07e4063d628/c1b63/2022_04_01_01.png",
            "srcSet": ["/static/478367b06c002dc00f04a07e4063d628/5a46d/2022_04_01_01.png 300w", "/static/478367b06c002dc00f04a07e4063d628/0a47e/2022_04_01_01.png 600w", "/static/478367b06c002dc00f04a07e4063d628/c1b63/2022_04_01_01.png 1200w", "/static/478367b06c002dc00f04a07e4063d628/d61c2/2022_04_01_01.png 1800w", "/static/478367b06c002dc00f04a07e4063d628/97a96/2022_04_01_01.png 2400w", "/static/478367b06c002dc00f04a07e4063d628/d9ed5/2022_04_01_01.png 2880w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`新`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aaadbc875c4d3d8ea2eb0b1152755440/23814/2022_04_01_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACg0lEQVQoz0WSXWiOYRjHnyMnzjl0gigKy2IZsxiKrTHzsZbNa1ZjH9TaEGmLRpMSBygiVlrixBxoX22JI1acidns3WvP+z7P+3x/76f7NnPVv+vu333/7+v6X5fiqyr+1DSBqqJpOqqaw7ZsojAg8P1FhEGA57rouo6RzzOTniWT+Y2u5yXyC1kJPI9IXMxppGczzMzMkk5n8D2POIqIwnBRUHD5fF6KappGLqfhOI7kPQHXQxGXxUNVzfJ54iufJr4wOv6RH5NTuI6DZVlSTAiLbFmmFDUMA9MwpKDg/0ER6jDP0PA4DWc62F1Vz97qFLsO1lFYVsXp85cJggDbdgjDEN8P/uYFznVFJ/GiNYrwRcTjvpesKixj16E6quqbOVDTSOHuKg6fbOHb959MTafRdINsTsc0bbI5jR+T02Qyc/JDoSMr/Cf46Hk/KzaVsrl4P1tLKynad1R+cLDuLHEcSyTJPEmSEESRRJQk8m0Ux9JXYZHiyZZh4N0IFbVN1DSc40iqlRNN7TJ3dvXi+75szRVVhCGx8F2cLZvAdvBsWwoKbxc8hMHR99Q2tVPf3ElzZzdP+gd4/WaQ6lSL5Oua2qlMtXL3+m3s8mPMFpQwV7KfzLoi1FQzntgC1/3f8rMXryjYWcH67eUcqD7BhRu9XLlzn7Vb9kgLtu2tZs2OclprGrGWr2ZqyTLSKzfyS1lKZkMxtutimSaKMFLE2NgHOq700NF9i9v3HjA89Ia3IyO0XbpGx9WbXOzqpeVyD30Pn+K3XSBf24hRfxbz+CnMrpty6qI4JZvNISBWQEzQcVzCKEY3xH6FcmEt4ZPny8GIUE2LGTHtMGTOtPGSZHEP/wBtZ92VGF180gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "新ブログ",
            "title": "新ブログ",
            "src": "/static/aaadbc875c4d3d8ea2eb0b1152755440/c1b63/2022_04_01_02.png",
            "srcSet": ["/static/aaadbc875c4d3d8ea2eb0b1152755440/5a46d/2022_04_01_02.png 300w", "/static/aaadbc875c4d3d8ea2eb0b1152755440/0a47e/2022_04_01_02.png 600w", "/static/aaadbc875c4d3d8ea2eb0b1152755440/c1b63/2022_04_01_02.png 1200w", "/static/aaadbc875c4d3d8ea2eb0b1152755440/d61c2/2022_04_01_02.png 1800w", "/static/aaadbc875c4d3d8ea2eb0b1152755440/97a96/2022_04_01_02.png 2400w", "/static/aaadbc875c4d3d8ea2eb0b1152755440/23814/2022_04_01_02.png 2856w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`エンジニア・デザイナーブログリニューアルの背景`}</h1>
    <p>{`まずは、 `}<strong parentName="p">{`なぜリニューアルしたか?`}</strong>{` という背景についてです。弊社のエンジニア・デザイナーブログの変遷と共にお話していきたいと思います。`}</p>
    <h2>{`エンジニア・デザイナーブログの変遷`}</h2>
    <h3>{`第 1 期(2016/04 ~ 2017/07)`}</h3>
    <p>{`Developer Blog は 2016/04 月に会社公式ブログにエンジニア向けの記事を掲載し始めたころから、しばらくは他の会社情報とともに掲載をしていました。記事内容はエンジニアが登壇したイベント情報や、今も続いている TechLunch(全社横断のエンジニア・デザイナー向け社内勉強会) の発表レポートなどがメインとなっていました。`}</p>
    <p>{`この頃は開発組織の人数もそこまで多くなかったのですが、メドレーの開発組織を社内の様々な部署の雰囲気と共にお伝えしようという目的がメインでした。まずは、メドレーの開発組織としてのプレゼンスを高め、プロダクトを内製で日々開発をしていることを、外部の皆さんに知ってもらおうという目的が一番大きかったように思います。`}</p>
    <p>{`このような形で 2017 年中頃までは会社公式ブログでの更新をしていましたが、開発組織が拡大するにつれ、組織として出来ることも増えてきました。それに伴い、これまでのように「開発組織の存在のアピール」や「組織の取り組み」に加えて、純粋に技術的な側面をもっと打ち出していき「医療 x IT」というテーマにメドレーはどのように向きあっているかを知ってもらおうという機運が高まってきました。`}</p>
    <h3>{`第 2 期(2017/07 ~ 2022/03)`}</h3>
    <p>{`こうした運びで新しく Medley Developer Blog を 2017/07 月に立ち上げることになりました。会社公式ブログから完全に独立したテックブログということで、目論見通りにそれまで以上に技術的な投稿もできるようになりました。編集方式もこの時から現在までエンジニア・デザイナーが主体となっています。`}</p>
    <p>{`更新頻度も不定期だったものを月 1~2 回と増やし、コンスタントにメドレーの開発に関する話題を取り扱うようにしました。運営をしていた 5 年の間にいくつかの記事は、おかげさまではてなブックマークなどで`}<a parentName="p" {...{
        "href": "https://b.hatena.ne.jp/site/developer.medley.jp/?sort=count"
      }}>{`話題`}</a>{`になることもあり、第 1 期よりもさらに皆さんに読んでいただけるようなブログになりました。`}</p>
    <p>{`この間にメドレーも様々なエンジニア・デザイナーイベントにスポンサードさせていただいたりもして、ブログと合わせて一定のプレゼンスを得ることができてきたのではないかと思っています。特に「医療ヘルスケア業界」という馴染みが無い方にはハードルが高いと思われることが多い業界でのプロダクト開発に、一般的なインターネットテクノロジーを駆使しているという点を、色々な側面からお伝えできるようになったことは大きいと感じています。`}</p>
    <h3>{`現在(2022/03 ~)`}</h3>
    <p>{`そんなブログでしたが、開設から 5 年経ち以下のような課題が出てきました。`}</p>
    <ul>
      <li parentName="ul">{`会社がまだ上場前に作られたデザインだったため、現在のコーポレートサイトなどのトーンとズレが出てきている`}</li>
      <li parentName="ul">{`会社や組織規模が大きくなり、希薄になりがちな内部で働いている個人にもフォーカスが当てられるようなコンテンツが欲しい`}</li>
      <li parentName="ul">{`採用的な側面として、メドレーに興味を持った方へ提供できる情報がバラバラになっている`}</li>
    </ul>
    <p>{`以上の課題を解決するために、ブログのリニューアルをすることになりました。`}</p>
    <p>{`特に今年から全社的な方針として、メドレーのソフト面での話題にフォーカスしたコンテンツを作っていくということで、改めて公式`}<a parentName="p" {...{
        "href": "https://note.com/medley"
      }}>{`note`}</a>{`の更新に注力していることもあり、エンジニア・デザイナーブログもこの動きと連動する必要がありました。`}</p>
    <p>{`以上の理由から、コンテンツとしては従来通りの `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://developer.medley.jp/entries"
        }}>{`ブログ記事`}</a></strong>{` 、様々なメディアでメンバーが露出している `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://developer.medley.jp/interviews"
        }}>{`インタビュー記事`}</a></strong>{` 、イベントなどで使われた `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://speakerdeck.com/medley"
        }}>{`発表スライド`}</a></strong>{` を全て見られるような総合的なサイトにしていくこととなりました。`}</p>
    <p>{`インタビューはこれまでもメンバーが様々なメディアに出ていたり、スライドも以前から Speaker Deck を更新していたのですが、まとまった形での提供ができていませんでした。`}</p>
    <p>{`今回のリニューアルで、これらの要素をまとめて皆さんにお届けできるようになったため、「Medley Developer Blog」から「Medley Developer Portal」と名称変更をしました。`}</p>
    <p>{`これからも、メドレーの開発組織をより外部の皆さんに知ってもらうために、運営ができればと考えていますので、よろしくお願いします。`}</p>
    <h1>{`エンジニア・デザイナーブログリニューアルの技術面について`}</h1>
    <p>{`さて、ここまでリニューアルの背景をお伝えしましたが、ここからは今回使った技術面について軽く触れていこうと思います。`}</p>
    <h2>{`使用技術について`}</h2>
    <p>{`旧ブログは「はてなブログ」での運用をしていました。リニューアルにあたって上記の課題を解決するためには、独自にブログを開発したほうがよいと考えました。新ブログは以下の技術を使って開発しました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/"
        }}>{`Gatsby`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://emotion.sh/docs/introduction"
            }}>{`Emotion`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.typescriptlang.org/"
            }}>{`TypeScript`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.netlify.com/"
        }}>{`Netlify`}</a></li>
    </ul>
    <p>{`Gatsby に決めた理由は大きくは以下でした。`}</p>
    <ul>
      <li parentName="ul">{`既に弊社内で Gatsby の導入実績があるため、社内のメンバーがいざとなったら触れる`}</li>
      <li parentName="ul">{`ブログ + α のサイトを作るのに十分な柔軟性がある`}</li>
      <li parentName="ul">{`公式やコミュニティプラグインが充実しており、開発が省力化できる`}</li>
    </ul>
    <p>{`Gatsby は精力的にアップデートが行なわれており、早いサイクルで進化するのも魅力の 1 つでした。`}</p>
    <p>{`Gatsby 製サイトをどのように公開するかは、悩んだのですが、結果的に事例も多くありデプロイの簡易さや機能の豊富さを考えて Netlify にしました。`}</p>
    <h2>{`はてなブログからの記事移行について`}</h2>
    <p>{`最初から旧ブログでのコンテンツは新ブログに移行することがマストだったので、まずはどのように移行ができるかということを考慮することから始めました。はてなブログは通常のエクスポートだと Movable Type 方式になるので、なんとか Markdown に変換するか…などと考えていました。`}</p>
    <p>{`しかし、`}<a parentName="p" {...{
        "href": "https://github.com/x-motemen/blogsync"
      }}>{`blogsync`}</a>{`というはてなブログの CLI クライアントを通すとローカルに Markdown ファイルとしてブログエントリを同期することが可能だったため、`}</p>
    <ol>
      <li parentName="ol">{`blogsync で全ての旧ブログコンテンツをローカルに同期(ダウンロード)`}</li>
      <li parentName="ol">{`ローカルに Markdown ファイルとしてダウンロードできたブログコンテンツを Gatsby 製の新ブログにコピー`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`gatsby-source-filesystem`}</code>{` でコピーした Markdown ファイルを読み込みブログとして表示`}</li>
    </ol>
    <p>{`という手順で既存のブログ記事を全て移行することができました。`}</p>
    <p>{`また、ドメインについては独自ドメインをそのまま新ブログにも移行することとしたため、はてなブログと同じ URL 形式でブログが読み込めるようにルーティングをしました。`}</p>
    <h2>{`苦労した部分`}</h2>
    <p>{`今回の開発で苦労した部分をダイジェストで書いていきます。自分のニーズに合わせて調べてみても、特に深い部分に関して、あまり情報が出てこないというパターンが多かったように思います。`}</p>
    <h3>{`バージョン固有の情報や、プラグインの情報が少なかった`}</h3>
    <p>{`開発当初は Gatsby v4 が出たばかりだったので、何かに困って検索しても公式以外の情報は以前のバージョンで使えないことが多かったです。`}</p>
    <p>{`またプラグイン関係の情報も調べる内容によっては中々目的の情報が出てこなかったりしました。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gatsby-plugin-image`}</code>{` 周りで顕著な印象だったので、画像周りの表示に泣かされることがありました。`}</p>
    <p>{`他にも Markdown 表示は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gatsby-plugin-mdx`}</code>{` を使用していますが、従来の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gatsby-transformer-remark`}</code>{` と情報が混在している印象がありましたので混乱することも。`}</p>
    <h3>{`ページネーションでベストなプラグインが見つからなかった`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/docs/adding-pagination/"
      }}>{`公式`}</a>{`ガイドを参考にページネーションを自前で作っていたのですが、`}<a parentName="p" {...{
        "href": "https://graphql.org/"
      }}>{`GraphQL`}</a>{`から持ってきたデータを表示するだけではあまりユーザビリティが良くなかったため、プラグインなど探したのですがこれといったものが見つからなかったです。`}</p>
    <p>{`公式ガイド参考に作ると延々記事が増えたらページネーションの数も増えていってしまい微妙な感じでした…。自前で制御も考えましたが、最終的には`}<a parentName="p" {...{
        "href": "https://mui.com/api/pagination/"
      }}>{`mui/pagination`}</a>{`コンポーネントを組み合わせることで対応しました。`}</p>
    <h3>{`OGP 画像自動生成に関して情報が少ない`}</h3>
    <p>{`OGP 画像は自動生成にしたいと思いましたが、案外ニーズが無いのか情報が少なかった印象です。最終的に`}<a parentName="p" {...{
        "href": "https://blog.kentarom.com/create-gatsbyjs-plugin-to-dynamically-generate-og-images/"
      }}>{`こちら`}</a>{`のブログを発見し、`}<a parentName="p" {...{
        "href": "https://github.com/kentaro-m/catchy-image"
      }}>{`kentaro-m/catchy-image`}</a>{`を使って生成するようにしました。`}</p>
    <h2>{`これからやりたいこと`}</h2>
    <p>{`機能の拡充以外だと、今まではできなかった`}<a parentName="p" {...{
        "href": "https://github.com/textlint/textlint"
      }}>{`textlint`}</a>{`での校正などをして、編集時の負荷軽減などをやっていきたいと考えています。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`以上、 Developer Portal のリニューアルについて書かせていただきました。 引き続き、情報発信などをしていきメドレー開発組織について、皆さんに知っていただければと思います。`}</p>
    <p>{`最後になりますが、医療ヘルスケアのプロダクト開発に(このようなブログ製作も)関わっていきたい!という方はぜひ下記よりご応募お待ちしております!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      